@use '../../index.scss' as index;

.home {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-content: center;

  .title {
    font-family: 'Yellowtail', sans-serif;
    font-size: 6em;
    text-align: center;

    @media( max-width: 600px), (max-height: 600px) {
      font-size: 4em;
    }
  }

  .home-content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    align-content: center;

    @media(max-width: 600px) {
      flex-direction: column;
    }

    .image-container {
      height: 500px;
      width: 500px;
      border-radius: 400px;
      overflow: hidden;

      @media(max-width: 800px), (max-height:800px) {
        height: 300px;
        width: 300px;
      }

      @media(max-width: 600px), (max-height: 600px) {
        height: 200px;
        width: 200px;
      }
      display: flex;

      justify-content: center;
      align-items: center;

      .profile-image {
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center center;

        box-shadow: 4px 4px 15px #999;
      }
    }


    .right {
      display: flex;
      flex-direction: column;

      align-content: center;

      .text {
        text-align: center;
        font-size: 3em;

        @media(max-width: 600px), (max-height: 600px) {
          font-size: 2em;
        }
      }
    }
  }
}
